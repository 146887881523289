import React, { useState, useEffect } from "react";
import axios from "axios";
import "./psspotprice.css";

const config = require("../../config.js");

export const PSSpotPrice = () => {
  const [ngPrice, setNgPrice]   = useState(0.000);
  const [ngChange, setNgChange] = useState(0.000);
  const [clPrice, setClPrice]   = useState(0.000);
  const [clChange, setClChange] = useState(0.000);
  const [rPrice, setRPrice]     = useState(0.000);
  const [rChange, setRChange]   = useState(0.000);
  const [bPrice, setBPrice]     = useState(0.000);
  const [bChange, setBChange]   = useState(0.000);
  const [iPrice, setIPrice]     = useState(0.000);
  const [iChange, setIChange]   = useState(0.000);

  useEffect(() => {
    const timer = setInterval(() => getSpotPrice(), 9000);

    return () => clearInterval(timer);
  }, []);

  const getSpotPrice = () => {
    axios({
      url: `${config.server_url}/getSpotPrice`,
      method: "POST",
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    }).then(response => {
      if (!response.data.error) {
        setNgPrice(response.data.res.ngPrice);
        setNgChange(response.data.res.ngChange);
        setClPrice(response.data.res.clPrice);
        setClChange(response.data.res.clChange);
        setRPrice(response.data.res.rPrice);
        setRChange(response.data.res.rChange);
        setBPrice(response.data.res.bPrice);
        setBChange(response.data.res.bChange);
        setIPrice(response.data.res.iPrice);
        setIChange(response.data.res.iChange);
      }
    }).catch(error => {
      console.log(error);
    })
  };

  const showChangeValue = (value) => {
    let valueString = value.toFixed(2);
    let sign = (value < 0) ? "-" : "+";
    return (value >= 1 || value <= -1) ? valueString : `${sign}.${valueString.split(".")[1]}`;
  }

  const changeValueClassName = (value) => {
    return (value < 0) ? "text-change text-danger" : "text-change text-success";
  }

  return (
    <div className="header-spot-prices">
      <span className="text-product">Henry </span>: $<span className="text-price">{ngPrice.toFixed(3)}</span> <span className={changeValueClassName(ngChange)}>({showChangeValue(ngChange)})</span> <span className="separator"> </span> 
      <span className="text-product"> WTI </span>: $<span className="text-price">{clPrice.toFixed(2)}</span> <span className={changeValueClassName(clChange)}>({showChangeValue(clChange)})</span> <span className="separator"> </span> 
      <span className="text-product"> WTI CMA </span>: $<span className="text-price">{rPrice.toFixed(2)}</span> <span className={changeValueClassName(rChange)}>({showChangeValue(rChange)})</span> <span className="separator"> </span>
      <span className="text-product"> Brent </span>: $<span className="text-price">{bPrice.toFixed(2)}</span> <span className={changeValueClassName(bChange)}>({showChangeValue(bChange)})</span> <span className="separator"> </span>
      <span className="text-product"> Brent CMA </span>: $<span className="text-price">{iPrice.toFixed(2)}</span> <span className={changeValueClassName(iChange)}>({showChangeValue(iChange)})</span>
    </div>
  );
}

export default PSSpotPrice;