import React, { useEffect, useState } from "react";
import { Table, Menu, Dropdown, Checkbox, Divider, Button } from "antd";
import { DownCircleTwoTone } from "@ant-design/icons";
import ReactDragListview from "react-drag-listview";

const PSDragColumntable = (props) => {
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [titleClass, setTitleClass] = useState("option-table-title");

  const [visibleColumns, setVisibleColumns] = useState(() => {
    return !props.alignStatus ? {
      strike: true,
      call: true,
      put: true,
      vol: false,
      c_settle: false,
      p_settle: false
    } : {
      strike: false,
      call: true,
      put: true,
      vol: false,
      c_settle: false,
      p_settle: false
    };
  });

  const onChange = (event) => {
    setVisibleColumns(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.checked
      };
    })
  };

  const menu = (
    <Menu>
      <Menu.Item key="1">
        <Checkbox
          onChange={onChange}
          name="strike"
          checked={visibleColumns.strike}
        >
          Strike
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="2">
        <Checkbox
          onChange={onChange}
          name="call"
          checked={visibleColumns.call}
        >
          Call
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="3">
        <Checkbox
          onChange={onChange}
          name="put"
          checked={visibleColumns.put}
        >
          Put
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="4">
        <Checkbox
          onChange={onChange}
          name="vol"
          checked={visibleColumns.vol}
        >
          Volatility
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="5">
        <Checkbox
          onChange={onChange}
          name="c_settle"
          checked={visibleColumns.c_settle}
        >
          Call Settlement
        </Checkbox>
      </Menu.Item>
      <Menu.Item key="6">
        <Checkbox
          onChange={onChange}
          name="p_settle"
          checked={visibleColumns.p_settle}
        >
          Put Settlement
        </Checkbox>
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {
    setColumns(prev => {
      let newColumns = [];
      if (visibleColumns.strike) {
        newColumns.push(props.columns[0]);
      }

      if (visibleColumns.call) {
        newColumns.push(props.columns[1]);
      }

      if (visibleColumns.put) {
        newColumns.push(props.columns[2]);
      }

      if (visibleColumns.vol) {
        newColumns.push(props.columns[3]);
      }
      if (visibleColumns.c_settle) {
        newColumns.push(props.columns[4]);
      }
      if (visibleColumns.p_settle) {
        newColumns.push(props.columns[5]);
      }
      return newColumns;
    });
    setDataSource(prev => props.dataSource);
    setTitleClass((prev) => {
      if (props.alignStatus) {
        return "option-table-title first-table";
      } else {
        return "option-table-title";
      }
    });
  }, [props]);
  
  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const current = [...columns];
      const item = current.splice(fromIndex, 1)[0];
      current.splice(toIndex, 0, item);
      setColumns((prev) => current);
    },
    nodeSelector: "th",
  };

  const onMouseOverHandler = (e) => {
    if (e.target.nodeName === "TH") {
      props.onDrag(true);
    }
  };

  const onMouseDownHandler = (e) => {
    if (e.target.tagName !== "TH") {
      props.onDrag(false);
    }
  };
  
  return (
    <div
      className="option-table"
      onMouseOver={onMouseOverHandler}
      onMouseDown={onMouseDownHandler}
    >
      <div className={titleClass}>
        {!props.straddle ? (
          <Dropdown overlay={menu} className="option-table-title-dropdown">
            <Button size="small">
              {props.monthTitle} <DownCircleTwoTone />
            </Button>
          </Dropdown>
        ) : 
          <Dropdown overlay={""} className="option-table-title-dropdown">
            <Button size="small">
              {props.monthTitle}
            </Button>
          </Dropdown>
        }
      </div>
      <ReactDragListview.DragColumn {...dragProps}>
        <Table
          columns={columns}
          pagination={false}
          dataSource={dataSource}
          bordered
          size="small"
          rowKey={(record) =>
            `${props.id}_${record.strike}_${record.month}`
          }
          rowClassName={(record, index) => {
            return record.rowClassName;
          }}
          tableLayout="fixed"
        />
      </ReactDragListview.DragColumn>
    </div>
  );
};

export default PSDragColumntable;
