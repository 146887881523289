import React, {useState } from "react";
import {Button} from "antd";
import {
  UpCircleFilled,
  DownCircleFilled
} from "@ant-design/icons";

import PSSettleTable from "../PSSettleTable";
const config = require("../../config");

export const PSFutureItem = (props) => {
  const featureTableTitle = config.products[props.product];
  const [collapsed, setCollapsed] = useState("collapsed");

  const onCollapseHandler = (event) => {
    setCollapsed(prev => prev == "" ? "collapsed" : "");
  }

  return (
    <div className={`future-item ${collapsed}`}>
      <div className="future-item-title" onClick={onCollapseHandler}>
        {featureTableTitle}
        {/* <a className="close-future-item" onClick={(event) => props.close(props.product)}>
          {collapsed === "collapsed" ? (
            <UpCircleFilled />
          ) : (
            <DownCircleFilled />
          )}
        </a> */}
        <a className="close-future-item">
          {collapsed === "collapsed" ? (
            <UpCircleFilled />
          ) : (
            <DownCircleFilled />
          )}
        </a>
      </div>
      <div className="future-item-content">
        <PSSettleTable product={props.product} />
      </div>
    </div>
  );
}

export default PSFutureItem;