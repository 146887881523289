import { notification } from "antd";

export const PSNotification = (props) => {
  notification[props.type]({
    message: props.message,
    description:
      props.description
  });
};

export default PSNotification;