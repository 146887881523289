import React, { useState, useEffect } from "react";
import axios from "axios";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import "./psAdmin.css";
import { Card, Divider } from 'antd';
import { Row, Col } from 'antd';
import { Table, Tag, Space } from 'antd';
import { Form, Input, Button, Checkbox, Switch, Popconfirm } from 'antd';
import PSNotification from "../PSNotification";

const config = require("../../config.js");
const qs = require('qs');
const generator = require('generate-password');

export const PSAdmin = () => {

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Register Date',
      dataIndex: 'registerDate',
      key: 'registerDate',
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'tags',
      render: tag => {
        let color = tag === 'Active' ? 'green' : 'volcano'
        return (
          <Tag color={color} key={tag}>
            {tag.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={(e) => handleEditUserList(record)}>Edit</a>
          {/* <a onClick={(e) => handleDeleteUserinfo(record)}>Delete</a> */}

          <Popconfirm
            title="Are you sure to delete this User?"
            onConfirm={(e) => handleDeleteUserinfo(record)}
            onCancel={deleteCancel}
            okText="Yes"
            cancelText="No"
          >
            <a href="#">Delete</a>
          </Popconfirm>
          
        </Space>
      ),
    },
  ];
 
  const [editState, setEditState] = useState(false);
  const [userList, setUserList] = useState([]);
  const [UserEditedStatus, setUserEditedStatus] = useState(false);
  const [userInfo, setUserInfo] = useState({
    name: "",
    email: "",
    password: "",
    status: 0,
  });
  const [product, setProduct] = useState({
    PHE: false,
    T: false,
    R: false,
    B: false,
    I: false,
    Pricer: false
  });

  const handleEditUserList = (record) => {
    setUserInfo(prev => record);
    setEditState(true);
    setProduct(prev => {
      let current = {
        PHE: false,
        T: false,
        R: false,
        B: false,
        I: false,
        Pricer: false
      };

      if (record.privi) {
        const privi_list = record.privi.split(',');
        for (let i = 0; i < privi_list.length; i++) {
          current[privi_list[i]] = true;
        }
      }
      return current;
    })
  };

  const deleteCancel = () => {
    console.log("Delete canceled");
  }

  const handleDeleteUserinfo = (record) => {

    const data = {
     userID: record.id
    };

    axios({
      method: "POST",
      url: `${config.server_url}/deleteUserInfo`,
      data: qs.stringify(data),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    }).then(response => {
      if (response.data.error) {
        PSNotification({
          type: "warning",
          message: "Warning",
          description: response.data.message
        })
        console.log(response.data.message)
      } else {
        PSNotification({
          type: "success",
          message: "Success",
          description: response.data.message
        })
        setUserEditedStatus(prev => !prev);
      }
    })
  }

  const addUserHandler = (event) => {
    setEditState(false);
    setUserInfo([]);
    setProduct({
      PHE: false,
      T: false,
      R: false,
      B: false,
      I: false,
      Pricer: false
    })
  }

  const GeneratePassword = (event) => {
    const password = generator.generate({
      length: 10,
      numbers: true
    });

    setUserInfo(prev => {
      return {
        ...prev,
        password: password
      }
    })
  }

  const onUserEditHandler = (event) => {
    setUserInfo(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.value
      }
    })
  }

  const onUserStatusChecked = (value) => {
    setUserInfo(prev => {
      return {
        ...prev,
        status: value
      }
    })
  }

  const onUserSaveHandler = (event) => {
    const priviList = [];
    for (let item in product) {
      if (product[item]) {
        priviList.push(item);
      }
    }

    const data = {
      userId: userInfo.id,
      userName: userInfo.name,
      userEmail: userInfo.email,
      userPassword: userInfo.password,
      userStatus: (userInfo.status) ? 1 : 0,
      privilege: priviList.join(",")
    };

    axios({
      method: "POST",
      url: `${config.server_url}/saveUserInfo`,
      data: qs.stringify(data),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    }).then(response => {
      if (response.data.error) {
        PSNotification({
          type: "warning",
          message: "Warning",
          description: response.data.message
        })
        console.log(response.data.message)
      } else {
        PSNotification({
          type: "success",
          message: "Scuuess",
          description: response.data.message
        })
        setUserEditedStatus(prev => !prev);
      }
    })
  }

  useEffect(() => {
    axios({
      method: "POST",
      url: `${config.server_url}/getUserList`,
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
      .then((response) => {
        if (!response.data.error) {
          setUserList((prev) => {
            return response.data.res;
          });
        }
      })
      .catch((error) => {
        PSNotification({
          type: "warning",
          message: "Warning",
          description: error.message
        })
        console.log(error);
      });
  }, [UserEditedStatus]);

  const handleCheck = (event) => {
    setProduct(prev => {
      return {
        ...prev,
        [event.target.name]: event.target.checked
      }
    })
  }
  return (
    <>
      <div className="userManage site-card-border-less-wrapper">
        <Card title="" bordered={true}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12} md={12} sm={24} xs={24}>
              <Table
                className="userList"
                columns={columns}
                dataSource={userList}
                title={() => 'User Management'}
              />
            </Col>
            <Col span={12} md={12} sm={24} xs={24}>
              <div className="registerPanel">
                {editState ? (
                  <div className="registerPanelHeader">
                    <h3>Edit User Info</h3>
                    <Button className="addUserInfo" type="primary" onClick={addUserHandler}>Add New User</Button>
                  </div>
                ) : (
                    <div className="registerPanelHeader">
                      <h3>Add User Info</h3>
                    </div>
                  )}
                <Form>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={12} sm={12} xs={24}>
                      <label htmlFor="userName">User Name</label>
                      <Input
                        type="text"
                        value={userInfo.name}
                        name="name"
                        onChange={onUserEditHandler}
                        required />
                    </Col>
                    <Col span={12} sm={12} xs={24}>
                      <label htmlFor="email">Email</label>
                      <Input
                        type="email"
                        value={userInfo.email}
                        name="email"
                        onChange={onUserEditHandler}
                        required />
                    </Col>
                    <Col span={12} sm={12} xs={24}>
                      <label htmlFor="password">Password</label>
                      <Input.Password
                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        name="password"
                        onChange={onUserEditHandler}
                        value={userInfo.password}
                        required
                      />
                    </Col>
                    <Col span={12} sm={12} xs={24} className="registerUserBtnGroup">
                      <Button className="genPassword" type="primary" onClick={GeneratePassword}>Generate New Password</Button>
                      <div>
                        <span htmlFor="status" className="statusLabel">Status</span>
                        <Switch
                          className="statusCheckBox"
                          checked={userInfo.status}
                          onChange={onUserStatusChecked}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Divider />
                  <div className="permissionPanel">
                    <h3>Privilege</h3>
                    <Form>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col span={8}>
                          <Checkbox name="PHE" checked={product.PHE} onChange={handleCheck}>HENRY(NG)</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            name="T" checked={product.T} onChange={handleCheck}>WTI</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            name="R" checked={product.R} onChange={handleCheck}>WTI CMA</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            name="B" checked={product.B} onChange={handleCheck}>BRENT</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            name="I" checked={product.I} onChange={handleCheck}>BRENT CMA</Checkbox>
                        </Col>
                        <Col span={8}>
                          <Checkbox
                            name="Pricer" checked={product.Pricer} onChange={handleCheck}>PRICER</Checkbox>
                        </Col>
                        <Col span={24}>
                          <Button className="userSave" type="primary" onClick={onUserSaveHandler}>Save</Button>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Card>
      </div>
    </>)
}

export default PSAdmin;