module.exports = {
  // server_url: "http://127.0.0.1:3001",
  server_url: "https://www.optionlytics.app",
  products: {
    PHE: "HENRY (NG)",
    T: "WTI (CL)",
    R: "WTI CMA",
    B: "BRENT",
    I: "BRENT CMA",
  },
  productsList: [
    "HENRY", "WTI", "WTI CMA", "BRENT", "BRENT CMA"
  ]
};
