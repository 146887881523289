import "./App.css";

import {
  Route,
  Switch,
  Redirect,
  BrowserRouter as Router,
} from "react-router-dom";
import PSNavBar from "./components/PSNavBar";
import PSHome from "./components/PSHome";
// import PSNgPage from "./components/PSNgPage";
import PSClPage from "./components/PSClPage";
import PSRPage from "./components/PSRPage";
import PSBPage from "./components/PSBPage";
import PSIPage from "./components/PSIPage";
import PSPricer from "./components/PSPricer";
import PSLogin from "./components/PSLogin";
import PSForgotPassword from "./components/PSForgotPassword";
import PSResetPassword from "./components/PSResetPassword";
import PSRegister from "./components/PSRegister";
import PSAdmin from "./components/PSAdmin";

function App() {
  return (
    <>
      <Router>
        <div className="App">
          <PSNavBar />
          <div className="content">
            <Switch>
              <PrivateRoute exact path="/" component={PSHome} />
              {/* <PrivateRoute path="/ng" component={PSNgPage} /> */}
              <PrivateRoute path="/cl" component={PSClPage} />
              <PrivateRoute path="/wti_cma" component={PSRPage} />
              <PrivateRoute path="/brent" component={PSBPage} />
              <PrivateRoute path="/brent_cma" component={PSIPage} />
              <PrivateRoute path="/pricer" component={PSPricer} />
              <Route path="/login" component={PSLogin} />
              <Route path="/register" component={PSRegister} />
              <Route path="/forgot-password" component={PSForgotPassword} />
              <Route path="/reset-password" component={PSResetPassword} />
              <Route path="/logout" component={PSRegister} />
              <PrivateRoute path="/admin" component={PSAdmin} />
              <Route />
            </Switch>
          </div>
        </div>
      </Router>
    </>
  );
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      loggedIn(props) ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const loggedIn = (props) => {
  let user = localStorage.getItem("currentUser");
  let isAdmin = 0;
  if ( typeof user == "undefined" || user == null) {
    user = {
      ID: 0,
      display_name: "",
      is_admin: "0",
      privi: "PHE,T,R,B,I,Pricer",
      token: "",
      userId: 0,
      user_activation_key: "",
      user_email: "",
      user_login: "",
      user_nicename: "",
      user_registered: "",
      user_status: 1,
      user_url: "",
    };
    localStorage.setItem("currentUser", JSON.stringify(user));
  } else {
    isAdmin = JSON.parse(user).is_admin;
  }
  if (props.location.pathname == '/admin' && isAdmin == 0) return false; 
  return true;
  // const user = localStorage.getItem("currentUser");
  // if (typeof user !== "undefined" && user != null) {
  //   return true;
  // }
  // return false;
};

export default App;
