import React, { useState, useEffect, useRef } from "react";
import { HotTable } from "@handsontable/react";
import { Row, Col, Card, Button, Typography } from "antd";
import moment from "moment";
import axios from "axios";

import "handsontable/dist/handsontable.full.css";
import "./pspricer.css";

const {Text} = Typography;
const config = require("../../config");
const md5 = require("md5");
const qs = require("qs");

export const PSPricer = () => {
  const stripMonthList = [];
  const inputTable = useRef(null);
  const resultTable = useRef(null);

  for (let i = 0; i < 50; i++) {
    stripMonthList.push(moment().add(i, "M", true).format("MMMYYYY"));
  }

  const [dataChanged, setDataChanged] = useState(md5(moment().format()))
  const [resultDataChanged, setResultDataChanged] = useState(md5(moment().format()))
  const [inputPricerData, setInputPricerData] = useState([
    {
      buyOrSell: "",
      putOrCall: "",
      commodity: "",
      strip: false,
      beginMonth: "",
      endMonth: "",
      strike: "",
      volume: "",
      pxAdjust: "",
      volAdjust: "",
    }
  ]);

  const [inputTableSettings, setInputTableSettings] = useState({
    data: [],
    rowHeaders: true,
    colHeaders: [
      "Buy/Sell",
      "Put/Call",
      "Commodity",
      "Strip",
      "Beginning Month",
      "End Month",
      "Strike",
      "Volume",
      "PxAdjust",
      "VolAdjust",
    ],
    rowHeights: 25,
    colWidths: 150,
    stretchH: "last",
    manualColumnResize: true,
    columns: [
      {
        data: 'buyOrSell',
        type: "dropdown",
        source: ["Buy", "Sell"],
      },
      {
        data: 'putOrCall',
        type: "dropdown",
        source: ["Put", "Call"],
      },
      {
        data: 'commodity',
        type: "dropdown",
        source: config.productsList
      },
      {
        data: 'strip',
        type: 'checkbox'
      },
      {
        data: 'beginMonth',
        type: 'dropdown',
        source: stripMonthList
      },
      {
        data: 'endMonth',
        type: 'dropdown',
        source: stripMonthList
      },
      {
        data: 'strike',
        type: 'numeric'
      },
      {
        data: 'volume',
        type: 'numeric'
      },
      {
        data: 'pxAdjust',
        type: 'numeric'
      },
      {
        data: 'volAdjust',
        type: 'numeric'
      },
    ],
    hiddenColumns: {
      columns: [5]
    },
    beforeChange: (changes, source) => {
      if (changes[0][0] == inputPricerData.length - 1) {
        setInputPricerData(prev => {
          const current = prev;
          current.push({
            buyOrSell: "",
            putOrCall: "",
            commodity: "",
            strip: false,
            beginMonth: "",
            strike: "",
            volume: "",
            pxAdjust: "",
            volAdjust: "",
          })
          return current;
        })
      }

      changes.forEach(([row, prop, oldValue, newValue]) => {
        setInputPricerData(prev => {
          let current = prev;
          current[row][prop] = newValue;
          return current;
        })
      });
      
      setDataChanged(md5(moment().format()));
    },
    licenseKey: "non-commercial-and-evaluation",
  });

  const [resultData, setResultData] = useState([{
    rowTitle: "Full Structure",
    optionTitle: "",
    putOrCall: "",
    strike: "",
    underlyingPrice: "",
    underlyingVol: "",
    settle: "",
    premium: "",
    change: "",
    notionalVolume: "",
    expiration: "",
    notionalDelta: ""
  }]);

  const [resultTableSettings, setResultTableSettings] = useState({
    data: [],
    rowHeaders: false,
    colHeaders: [
      "",
      "",
      "Put/Call",
      "Strike",
      "Underlying Price",
      "Underlying Vol",
      "Settle",
      "Premium",
      "Change",
      "Notional Volume",
      "Expiration",
      "Notional Delta",
    ],
    rowHeights: 25,
    manualColumnResize: true,
    columns: [
      {
        data: 'rowTitle'
      },
      {
        data: 'optionTitle'
      },
      {
        data: 'putOrCall',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
      {
        data: 'strike',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
      {
        data: 'underlyingPrice',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
      {
        data: 'underlyingVol',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
      {
        data: 'settle',
      },
      {
        data: 'premium',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#9cf9a7';
          td.style.textAlign = 'right';
          td.innerHTML = value;
        }
      },
      {
        data: 'change',
      },
      {
        data: 'notionalVolume',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
      {
        data: 'expiration',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
      {
        data: 'notionalDelta',
        type: 'numeric',
        renderer: (instance, td, row, col, prop, value, cellProperties) => {
          td.style.background = '#e9f0ff';
          td.innerHTML = value;
        }
      },
    ],
    colWidths: 125,
    stretchH: "last",
    mergeCells: true,
    licenseKey: "non-commercial-and-evaluation",
  });

  const [mergeItems, setMergeItems] = useState([]);

  useEffect(() => {
    setInputTableSettings((prev) => {
      for (let i = 0; i < inputPricerData.length; i++) {
        if (inputPricerData[i].strip) {
          return {...prev, data:inputPricerData, hiddenColumns: { columns: []}};
        }
      }
      return { ...prev, data: inputPricerData, hiddenColumns: { columns: [5]}};
    });

    setResultDataChanged(md5(moment().format()));
  }, [dataChanged]);

  useEffect(async () => {
    await setResultTableSettings(prev => {
      return {...prev, data: resultData};
    })

    const plugin = resultTable.current.hotInstance.getPlugin('mergeCells');
    if (plugin.isEnabled()) {
      for (let i = 0; i < mergeItems.length; i ++) {
        plugin.merge(mergeItems[i], 0, mergeItems[i], 1);
      }
    } else {
      plugin.enablePlugin();
      for (let i = 0; i < mergeItems.length; i ++) {
        plugin.merge(mergeItems[i], 0, mergeItems[i], 1);
      }
    }
  }, [resultDataChanged])

  const onCalculateHandle = (event) => {
    
    const requestData = {
      inputData: JSON.stringify(inputPricerData)
    };
    
    axios({
      url: `${config.server_url}/getPricerResult`,
      method: "POST",
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    }).then(response => {
      if (!response.data.error) {
        setResultData(prev => {
          return response.data.res;
        })

        setMergeItems(prev => response.data.mergeItems);

        setResultDataChanged(md5(moment().format()));
      }
    }).catch(error => {
      console.log(error);
    });
  }
  
  const onResetHandler = (event) => {
    window.location.reload(false);
  }

  return (
    <div className="pricer-content">
      <Row justify="center">
        <Col span="20">
          <Card>
            <div className="pricer-buttons">
              <Button type="primary" onClick={onCalculateHandle} style={{width: 100}}>Calculate</Button>
              <Button type="primary" onClick={onResetHandler} style={{marginLeft: 20, width: 100}}>Reset</Button>
            </div>
            <div className="pricer-panel">
              <div className="pricer-structure-panel">
                <div className="structure-title">
                  <Text level={5}>Option Structures</Text>
                </div>
                <div className="structure-content">
                  <HotTable settings={inputTableSettings} ref={inputTable}></HotTable>
                </div>
              </div>
              <div className="pricer-result-panel">
                <div className="result-title">
                  <Text >Option Results</Text>
                </div>
                <div className="result-content">
                  <HotTable settings={resultTableSettings} ref={resultTable}></HotTable>
                </div>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default PSPricer;
