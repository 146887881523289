import {React, useState, useEffect} from "react";
import axios from 'axios';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import './psResetPassword.css';

const qs = require('qs');
const config = require("../../config.js");
export const PSResetPassword = (props) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    axios({
      method: "POST",
      url: `${config.server_url}/verifytoken`,
      data: {
      },
        headers: {
          "Content-Type": "application/json",
          'withCredentials': true,
          "x-access-token": token
        }
    }).then(response => {
      setUser({
        ...user,
        valid: true
      })
    }).catch(err =>{
      console.log('err')
      setUser({
        ...user,
        valid: false
      })
    })
  }, [])
  const [user, setUser] = useState({
    valid: false,
    userEmail: "",
    password: "",
    confirmPassword: ""
  });
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    varient: "success",
    message: "New message"
  });

  const handleChange = (event) => {
    var key = event.target.name;
    setUser({
      ...user,
      [key]: event.target.value
    })
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertStatus({
      ...alertStatus,
      open: false
    });
  }

  const handleSubmit = async (event) => {
    if (user.password !== user.confirmPassword) {
      setAlertStatus({
        varient: "error",
        message: "Password and Confirm Password does not matched!",
        open: true
      });
    };
    var data = {
      email: user.userEmail,
      password: user.password
    };
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    
    await new Promise((resolve, reject) => {

      axios({
        method: "POST",
        url: `${config.server_url}/reset-password`,
        data: qs.stringify(data),
        headers: {
          'withCredentials': true,
          "x-access-token": token
        }
      }).then(response => {
        resolve(response)

        // setUser({
        //   ...user,
        //   valid: true
        // })
      }).catch(err =>{
        console.log('err');
        reject(err);
        // setUser({
        //   ...user,
        //   valid: false
        // })
      })
          
    });
    props.history.push('/login');
  }

  return (
    <div>
      {user.valid?
      <div>
        <Grid container spacing={3} justify="center" alignItems="flex-start">
          <Grid item xs={10} sm={6} md={5}>
            <Paper className="login-panel">
              <div className="card">
                <div className="card-header text-left">
                  <h3 className="card-header-title">Reset Password </h3>
                </div>
                <Divider />
                <div className="card-body">
                  {/* <div className="form-input-group">
                    <TextField required name="userEmail" label="E-mail Address" className="form-input" value={user.userEmail} onChange={handleChange}/>
                  </div> */}
                  <div className="form-input-group">
                    <TextField required name="password" label="Password" className="form-input" type="password" value={user.password} onChange={handleChange}/>
                  </div>
                  <div className="form-input-group">
                    <TextField required name="confirmPassword" label="Confirm Password" className="form-input" type="password" value={user.confirmPassword} onChange={handleChange}/>
                  </div>
                </div>
                <Divider />
                <div className="card-footer">
                  <Grid container justify="center">
                    <Grid item xs={12} >
                      <div className="text-center">
                        <Button variant="contained" className="primary-btn" onClick={handleSubmit}>
                          Reset
                        </Button>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
        <Snackbar open={alertStatus.open} autoHideDuration={6000} onClose={handleAlertClose} className="alert-font">
          <Alert onClose={handleAlertClose} severity={alertStatus.varient}>
            {alertStatus.message}
          </Alert>
        </Snackbar>
      </div>
      : 
      <div className="h1 text-center">Invalid or Expired URL</div>
      }
    </div>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default PSResetPassword;