import { React, useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { useMediaQuery } from "react-responsive";
import {PSSearchResult} from "../PSSearchResult";

import "antd/dist/antd.css";
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  DatePicker,
  Select,
  Divider,
  Tooltip,
  message,
  Modal,
  Form,
  Spin,
} from "antd";
import {
  DatabaseOutlined,
  SaveOutlined,
  MenuUnfoldOutlined,
  MergeCellsOutlined,
  FundViewOutlined,
  SlidersOutlined
} from "@ant-design/icons";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-dnd";
import PSDragColumntable from "../PSDragColumnTable";
import PSNotification from "../PSNotification";

import "./psHome.css";

const qs = require("qs");
const config = require("../../config.js");
const md5 = require("md5");

// const socket = io(config.server_url); // Socket connection I/O

const { Search } = Input;
const { RangePicker } = DatePicker;
const { Option } = Select;

const saveLayoutMessagekey = "saveLayout";
export const PSHome = () => {
  const currentUser = JSON.parse(localStorage.getItem("currentUser"));
  /* Responsive */
  const isMobile = useMediaQuery({ maxWidth: 480})
  const isTablet = useMediaQuery({maxWidth: 1024, minWidth: 481});

  /* Delcare state variables */
  const [cacheOptionData, setCacheOptionData] = useState([]); // Save response Data to state variable
  const [optionTableData, setOptionTableData] = useState([]); // Save main option Data to state variable

  const [showMonthCount, setShowMonthCount] = useState(7); // Set Month counts
  const [showStrikeCount, setShowStrikeCount] = useState(100); // Set Show strike Rows
  const [startStrike, setStartStrike] = useState(1.5); // Set minimum strike value
  const [endStrike, setEndStrike] = useState(3.5); // Set maximum strike value

  const [startMonth, setStartMonth] = useState(moment().add(1, "M")); // Set start month
  const [endMonth, setEndMonth] = useState(moment().add(showMonthCount, "M")); // Set last month

  const [minMonth, setMinMonth] = useState(moment());
  const [maxMonth, setMaxMonth] = useState(moment().add(13, "M"));

  const [dragItems, setDragItems] = useState([]); // Set all dragable tables to items

  const [disableDraggable, setDisableDraggable] = useState(false); // Flag variable to set dragable or not

  const [changeAlign, setChangeAlign] = useState(false);

  const [visibleCompareOption, setVisibleCompareOption] = useState(false);
  const [firstMonth, setFirstMonth] = useState(moment());
  const [lastMonth, setLastMonth] = useState(moment().add(showMonthCount, "M"));

  const [visibleStripOption, setVisibleStripOption] = useState(false);
  const [stripFirstMonth, setStripFirstMonth] = useState(moment());
  const [stripLastMonth, setStripLastMonth] = useState(
    moment().add(showMonthCount, "M")
  );

  const [spinning, setSpinning] = useState(false);
  const [stripSpinning, setStripSpinning] = useState(false);
  const [timerHash, setTimerHash] = useState("");
  const [searchResult, setSearchResult] = useState([]);

  const [monthChange, setMonthChange] = useState(false); // State for month period selection change
  const [compareHistory, setCompareHistory] = useState([]);
  const [stripHistory, setStripHistory] = useState([]);
  const [searchOption, setSearchOption] = useState(0);
  const [straddleVisible, setStraddleVisible] = useState(false);

  const handleSearchOption = (value) => {
   setSearchOption(value);
  }
  const selectBefore = (
    <Select className="select-before" defaultValue={0} style={{ minWidth: 110 }} onChange={handleSearchOption}>
      <Option value={0}>Normal</Option>
      <Option value={1}>Strip</Option>
      <Option value={2}>Straddle</Option>
      <Option value={3}>Alt Pricing</Option>
    </Select>
  );

  useEffect(() => {
    const requestData = {
      userid: currentUser.ID,
      contract: "PHE",
    };
    setSpinning(true);
    axios({
      method: "POST",
      url: `${config.server_url}/getSavedLayout`,
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
    .then((response) => {
      if (!response.data.error) {
        if (!response.data.res){
          refreshData(false, startStrike);
        } else {
          setSpinning(false);
          setMonthChange(false);
          setShowStrikeCount(response.data.res.showStrikeCount);
          setStartStrike(response.data.res.startStrike);
          setEndStrike(response.data.res.endStrike);
          setStartMonth(moment(response.data.res.startMonth));
          setEndMonth(moment(response.data.res.endMonth));
          setShowMonthCount(endMonth.diff(startMonth, "months", true) + 1);
          setCacheOptionData(prev => JSON.parse(response.data.res.dragItems));
          setTimerHash(md5(Date.now()));
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });

    axios({
      method: "POST",
      url: `${config.server_url}/get_strip_month`,
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
    .then((response) => {
      if (!response.data.error) {
        setMinMonth(moment(response.data.res.startMonth + "-01"));
        setMaxMonth(moment(response.data.res.endMonth + "-01"));
        setFirstMonth(moment(response.data.res.startMonth + "-01"));
        setStripFirstMonth(moment(response.data.res.startMonth + "-01"));
        // setLastMonth(moment(response.data.res.endMonth + "-01"));
        // setStripLastMonth(moment(response.data.res.startMonth + "-01"));
      }
    })
    .catch((error) => {
      console.log(error);
    });
    // const interval = setInterval(() => {
    //   refreshData(true, startStrike);
    // }, 5000);
    // return () => clearInterval(interval);
  }, []);

  /**
   * Set showMonthCount when startMonth & endMonth update
   */
  useEffect(() => {
    setShowMonthCount(endMonth.diff(startMonth, "months", true) + 1);
  }, [startMonth, endMonth]);

  /**
   * Set cacheOptionData when showMonthCount update
   */
  useEffect(() => {
    const interval = setInterval(() => {
      refreshData(true, startStrike)
    }, 5000);
    return () => clearInterval(interval);
  }, [showMonthCount, startStrike, endStrike, JSON.stringify(dragItems), JSON.stringify(cacheOptionData)]);

  /**
   * Update optionTableData from cacheOptionData when startStrike, endStrike, cacheOptionData, showStrikeCount changed.
   */
  useEffect(() => {
    console.log('cacheoption data changed', cacheOptionData);
    const tempTableData = [];
    let straddleTempData = [];

    let k = 0;
    for (let i = 0; i < cacheOptionData.length; i++) {
      const atm = parseFloat(cacheOptionData[i].atmValue);
      const limit = -0.05;

      let tableData = [];
      let atmPoint = 0;
      let atmCall = 0.0;
      let atmPut = 0.0;
      let rowCount = 0;

      const monthTitle =
        cacheOptionData[i].currentPrice == -1
          ? cacheOptionData[i].month
          : moment(cacheOptionData[i].month).format("MMMYY") +
            " : " +
            cacheOptionData[i].currentPrice +
            " : " +
            cacheOptionData[i].limitValue;

      k = cacheOptionData[i].currentPrice == -1 ? k : k + 1;

      for (let j = 0; j < cacheOptionData[i].result.length; j++) {
        const strike = parseFloat(cacheOptionData[i].result[j].strike);

        if (
          strike >= startStrike &&
          strike <= endStrike &&
          rowCount < showStrikeCount
        ) {
          let rowClassName = "";

          if (atm == strike) {
            rowClassName = "atm-value";
            atmPoint = rowCount;
            atmCall = parseFloat(cacheOptionData[i].result[j].call);
            atmPut = parseFloat(cacheOptionData[i].result[j].put);
            if (cacheOptionData[i].currentPrice != -1) {
              const straddleValue = atmCall + atmPut;
              straddleTempData.push({
                month: moment(cacheOptionData[i].month).format("MMMYY"),
                strike: cacheOptionData[i].result[j].strike,
                straddle: straddleValue.toFixed(3)
              })
            }
          }
          tableData.push({
            strike: cacheOptionData[i].result[j].strike,
            call: cacheOptionData[i].result[j].call,
            put: cacheOptionData[i].result[j].put,
            rowClassName: rowClassName,
            callGreeks: cacheOptionData[i].result[j].callGreeks,
            putGreeks: cacheOptionData[i].result[j].putGreeks,
            vol: cacheOptionData[i].result[j].vol.toFixed(2),
            c_settle: cacheOptionData[i].result[j].c_settle,
            p_settle: cacheOptionData[i].result[j].p_settle
          });
          rowCount++;
        } else if (rowCount >= showStrikeCount) {
          break;
        }
      }

      let j = 1;

      while (atmPoint - j >= 0 && atmPoint != 0) {
        const call = parseFloat(tableData[atmPoint - j].call);
        const put = parseFloat(tableData[atmPoint - j].put);

        if ((atmCall - limit >= call || atmPut + limit <= put) && j <= 3) {
          tableData[atmPoint - j].rowClassName = "semi-atm-value";
        } else if ((atmCall - limit * 2 >= call || atmPut + limit * 2 <= put) && j <= 7) {
          tableData[atmPoint - j].rowClassName = "qua-atm-value";
        } else if ((atmCall - limit * 3 >= call || atmPut + limit * 3 <= put ) && j <= 10) {
          tableData[atmPoint - j].rowClassName = "far-atm-value";
        } else {
          tableData[atmPoint - j].rowClassName = "not-atm-value";
        }
        j++;
      }

      j = 1;
      while (atmPoint + j < tableData.length && atmPoint != 0) {
        const call = parseFloat(tableData[atmPoint + j].call);
        const put = parseFloat(tableData[atmPoint + j].put);
        if ((atmCall + limit <= call || atmPut - limit >= put) && j <= 3) {
          tableData[atmPoint + j].rowClassName = "semi-atm-value";
        } else if ((atmCall + limit * 2 <= call || atmPut - limit * 2 >= put) && j <= 7) {
          tableData[atmPoint + j].rowClassName = "up-qua-atm-value";
        } else if ((atmCall + limit * 3 <= call || atmPut - limit * 3 >= put) && j <= 10) {
          tableData[atmPoint + j].rowClassName = "up-far-atm-value";
        } else {
          tableData[atmPoint + j].rowClassName = "up-not-atm-value";
        }
        j++;
      }

      tempTableData.push({
        monthTitle: monthTitle,
        data: tableData,
        optionKey: cacheOptionData[i].optionKey,
        startMonth: cacheOptionData[i].startMonth !== "undefined" ? cacheOptionData[i].startMonth : cacheOptionData[i].month
      });
    }

    tempTableData.unshift({
      monthTitle: "Straddle",
      data: straddleTempData,
      optionKey: "straddle",
      startMont: ""
    })

    setOptionTableData((prev) => tempTableData);
  }, 
  [
    JSON.stringify(cacheOptionData),
    showStrikeCount,
  ]
  );

  useEffect(() => {
    console.log('option table data changed', optionTableData);
    const defaultColumns = [
      {
        title: "Strike",
        dataIndex: "strike",
        align: "center",
        width: 40,
        className: "option-table-strike-col",
      },
      {
        title: "Call",
        dataIndex: "call",
        align: "center",
        className: "option-table-cell option-table-call-col",
        width: 40,
        render: (
          call,
          record // Show tooltips for greeks information at call row.
        ) => (
          <Tooltip
            placement="topLeft"
            title={() => (
              <div className="option-table-tooltip">
                <span className="tooltip-title">{`$ ${parseFloat(
                  record.strike
                ).toFixed(3)} Call`}</span>
                <Divider className="tooltip-divider" />
                <Row className="tooltip-body" justify={"space-between"}>
                  <span>{`Delta: ${record.callGreeks.delta}`}</span>
                  <span className="tooltip-space">{`Gamma: ${record.callGreeks.gamma}`}</span>
                  <span>{`Vega: ${record.callGreeks.vega}`}</span>
                </Row>
                <Row justify="start">
                  <span>{`Theta: ${record.callGreeks.theta}`}</span>
                  <span className="tooltip-space">{`Breakeven: ${record.callGreeks.breakeven}`}</span>
                </Row>
              </div>
            )}
          >
            {call}
          </Tooltip>
        ),
      },
      {
        title: "Put",
        dataIndex: "put",
        align: "center",
        className: "option-table-cell option-table-put-col",
        width: 40,
        render: (
          put,
          record // Show tooltips for greek information on put row.
        ) => (
          <Tooltip
            placement="topLeft"
            title={() => (
              <div className="option-table-tooltip">
                <span className="tooltip-title">{`$ ${parseFloat(
                  record.strike
                ).toFixed(3)} Put`}</span>
                <Divider className="tooltip-divider" />
                <Row justify={"space-between"}>
                  <span>{`Delta: ${record.putGreeks.delta}`}</span>
                  <span className="tooltip-space">{`Gamma: ${record.putGreeks.gamma}`}</span>
                  <span>{`Vega: ${record.putGreeks.vega}`}</span>
                </Row>
                <Row justify={"start"}>
                  <span>{`Theta: ${record.putGreeks.theta}`}</span>
                  <span className="tooltip-space">{`Breakeven: ${record.putGreeks.breakeven}`}</span>
                </Row>
              </div>
            )}
          >
            {put}
          </Tooltip>
        ),
      },
      {
        title: "Vol",
        dataIndex: "vol",
        align: "center",
        className: "option-table-cell option-table-vol-col",
        width: 40,
      },
      {
        title: "S.C",
        dataIndex: "c_settle",
        align: "center",
        className: "option-table-cell option-table-vol-col",
        width: 40,
      },
      {
        title: "S.P",
        dataIndex: "p_settle",
        align: "center",
        className: "option-table-cell option-table-vol-col",
        width: 40,
      }
    ];

    const straddleColumns = [
      {
        title: "Month",
        dataIndex: "month",
        align: "center",
        width: 40,
        className: "option-table-strike-col",
      },{
        title: "Strike",
        dataIndex: "strike",
        align: "center",
        width: 40,
        className: "option-table-strike-col",
      },{
        title: "Straddle",
        dataIndex: "straddle",
        align: "center",
        width: 40,
        className: "option-table-strike-col",
      },
    ];

    if (changeAlign) {
      const newDragItems = [];
      for (let i = 0; i < optionTableData.length; i++) {
        newDragItems.push({
          id: optionTableData[i].optionKey,
          dataSource: optionTableData[i].data,
          columns: defaultColumns,
          monthTitle: optionTableData[i].monthTitle,
          straddle: optionTableData[i].optionKey != "straddle" ? false : true
        });
      }
      setDragItems((prev) => newDragItems);
    } else {
      if (monthChange) {
        console.log('month change');
        const newDragItems = [];
        for (let i = 0; i < optionTableData.length; i++) {
          newDragItems.push({
            id: optionTableData[i].optionKey,
            dataSource: optionTableData[i].data,
            columns: optionTableData[i].optionKey != "straddle" ? defaultColumns : straddleColumns,
            monthTitle: optionTableData[i].monthTitle,
            straddle: optionTableData[i].optionKey != "straddle" ? false : true
          });
        }
        setDragItems((prev) => newDragItems);
      } else {
        setDragItems(prev => {
          let currentDragItems = prev;
  
          for (let i = 0; i < optionTableData.length; i++) {
            const dragItem = currentDragItems.find((obj) => {
              return obj.id === optionTableData[i].optionKey;
            })
            const oldItemIndex = currentDragItems.indexOf(dragItem);
            if (oldItemIndex == -1) {
              currentDragItems.push({
                id: optionTableData[i].optionKey,
                dataSource: optionTableData[i].data,
                columns: optionTableData[i].optionKey != "straddle" ? defaultColumns : straddleColumns,
                monthTitle: optionTableData[i].monthTitle,
                straddle: optionTableData[i].optionKey != "straddle" ? false : true
              });
            } else {
              currentDragItems[oldItemIndex].dataSource = optionTableData[i].data;
              currentDragItems[oldItemIndex].monthTitle = optionTableData[i].monthTitle;
            }
          }
          return [...currentDragItems];
        })
      }
    }
  }, [JSON.stringify(optionTableData)]);

  useEffect(() => {
    searchResult.forEach((result) => {
      if (result.type == 2) {
        const columns = [
          {
            title: "Structure",
            dataIndex: "struct",
            key: "struct" + result.key,
          },
          {
            title: "Strike",
            dataIndex: "strike",
            key: "strike" + result.key,
          },
          {
            title: "Straddle",
            dataIndex: "straddle",
            key: "straddle" + result.key,
          }
        ];
        PSSearchResult({
          columns: columns,
          message:  `${config.products[result.contract]}: (${result.query})`,
          dataSource: result.data,
          key: result.key,
          type: result.type,
          searchClose: onSearchResultClose
        })
      } else if (result.type == 1) {
        const columns = [
          {
            title: "Structure",
            dataIndex: "struct",
            key: "struct" + result.key,
          },
          {
            title: "Term",
            dataIndex: "term",
            key: "term" + result.key,
          },
          // {
          //   title: "Strike",
          //   dataIndex: "strike",
          //   key: "strike" + result.key,
          // },
          {
            title: "Value",
            dataIndex: "value",
            key: "value" + result.key,
          },
          {
            title: "Delta",
            dataIndex: "delta",
            key: "delta" + result.key
          },
          {
            title: "Ref",
            dataIndex: "ref",
            key: "ref" + result.key
          },
          {
            title: "Vol",
            dataIndex: "vol",
            key: "vol" + result.key
          }
        ];
        PSSearchResult({
          columns: columns,
          message:  `${config.products[result.contract]}: (${result.query})`,
          dataSource: result.data,
          key: result.key,
          type: result.type,
          searchClose: onSearchResultClose
        })
      } else {
        const columns = [
          {
            title: "Structure",
            dataIndex: "struct",
            key: "struct" + result.key,
          },
          {
            title: "Value",
            dataIndex: "value",
            key: "value" + result.key,
          },
          {
            title: "Delta",
            dataIndex: "delta",
            key: "delta" + result.key,
          },
          {
            title: "Ref",
            dataIndex: "ref",
            key: "ref" + result.key,
          },
          {
            title: "Vol",
            dataIndex: "vol",
            key: "vol" + result.key,
          },
        ];
        PSSearchResult({
          columns: columns,
          message:  `${config.products[result.contract]}: (${result.query})`,
          dataSource: result.data,
          key: result.key,
          type: result.type,
          searchClose: onSearchResultClose
        })
      }
    });
  }, [JSON.stringify(searchResult)]);

  const refreshData = (timer, start_strike) => {
    const requestData = {
      startStrike: start_strike,
      endStrike: endStrike,
      startMonth: startMonth.format("YYYY-MM-01"),
      endMonth: endMonth.format("YYYY-MM-01"),
      count: showMonthCount,
      compare: JSON.stringify(compareHistory),
      strip: JSON.stringify(stripHistory)
    };
    if (timer !== true) {
      setSpinning(true);
    }
    // Get option Data from server
    axios({
      method: "POST",
      url: `${config.server_url}/getPHEData`,
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
      .then((response) => {
        if (!response.data.error) {
          let ids = [];
          if (straddleVisible) {
            ids = dragItems.map(it => it.id);
          }
          else {
            ids = dragItems.slice(1).map(it => it.id);
          }
          let newCacheOptionData = response.data.res.sort((a, b) => {
            return ids.indexOf(a.month) - ids.indexOf(b.month);
          })
          console.log('new cacheoption data', newCacheOptionData);
          setCacheOptionData((prev) => {
            return newCacheOptionData;
          });
        }

        if (timer !== true) {
          setSpinning(false);
        }
      })
      .catch((err) => {
        console.log(err);
        if (timer !== true) {
          setSpinning(false);
        }
      });
    
    for (let i = 0; i < searchResult.length; i++) {
      const searchRequestData = {
        type: searchResult[i].type,
        query: searchResult[i].query,
        contract: searchResult[i].contract,
      };

      axios({
        method: "POST",
        url: `${config.server_url}/getSearchResult`,
        data: qs.stringify(searchRequestData),
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      })
        .then((response) => {
          if (!response.data.error) {
            let temp = [...searchResult]
            temp[i].data = response.data.res;
            setSearchResult(temp);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  /**
   * Handler to set search keyword
   * @return {void}
   * @param {String} value Search input value
   */
  const onSearch = (value) => {
    const inputQuery = value.toUpperCase();
    const requestData = {
      type: searchOption,
      query: inputQuery,
      contract: "PHE",
    };

    setSpinning(true);

    axios({
      method: "POST",
      url: `${config.server_url}/getSearchResult`,
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
      .then((response) => {
        setSpinning(false);
        if (!response.data.error) {
          const key = md5(inputQuery);

          let temp = searchResult;
          temp = temp.concat({
            key: key,
            query: inputQuery,
            contract: "PHE",
            data: response.data.res,
            type: searchOption
          });
          setSearchResult(temp);
        } else {
          message.error({
            content: "Error! Please fill out valid keywords.",
            saveLayoutMessagekey,
            duration: 2,
          });
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error({
          content: "Error! Server connection failed. Please try again later.",
          saveLayoutMessagekey,
          duration: 2,
        });
      });
  };

  const onSearchResultClose = (key) => {
    let temp = [...searchResult]
    temp = temp.filter((obj) => {
      return obj.key !== key;
    })
    setSearchResult(temp)
  }
  /**
   * Handler to set startStrike
   * @return {void}
   * @param {Event} event Set minimum strike Value
   */
  const onChangeStartStrikeHandler = (event) => {
    setStartStrike(event.target.value);
  };

  /**
   * Handler to set endStrike Value
   * @param {Event} event Set maximum strike value
   */
  const onChangeEndStrikeHandler = (event) => {
    setEndStrike(event.target.value);
  };

  /**
   * Set startMonth, endMonth values
   * @param {[moment, moment]} dates moment object range value
   * @param {[String, String]} dateStrings String range value
   */
  const onDateRangeChange = (dates, dateStrings) => {
    setStartMonth(dates[0]);
    setEndMonth(dates[1]);
    setMonthChange(true);
  };

  /**
   * Handler to set showStrikeCount
   * @param {int} value showing strike rows
   */
  const onShowRowChange = (value) => {
    setShowStrikeCount(value);
  };

  /**
   * Set changed order to dragItems
   * @param {String} sourceId container that source item is in
   * @param {*} sourceIndex source item's ID
   * @param {*} targetIndex target item's ID
   */
  const onDragChange = (sourceId, sourceIndex, targetIndex) => {
    if (targetIndex != sourceIndex) {
      let result = [];
      let newCacheOptionData = [];
      newCacheOptionData = swap(cacheOptionData, sourceIndex, targetIndex);
      setCacheOptionData(newCacheOptionData);
      if (straddleVisible) {
        result = swap(dragItems, sourceIndex, targetIndex);
      } else {
        result = swap(dragItems, sourceIndex + 1, targetIndex + 1);
      }
      return setDragItems(prev => {
        return result
      });
    }
  };

  /**
   * Set disible selection in daterangepicker
   * @param {moment} current selected date
   */
  const disabledDate = (current) => {
    return (
      current &&
      (current < minMonth || current > maxMonth)
    );
  };

  const onSaveLayoutHandler = (event) => {
    saveLayout();
  };

  /**
   * Save layout function
   */
  const saveLayout = () => {
    let requestData = {
      userid: currentUser.ID,
      showStrikeCount: showStrikeCount,
      startStrike: startStrike,
      endStrike: endStrike,
      startMonth: startMonth.format("YYYY-MM-01"),
      endMonth: endMonth.format("YYYY-MM-01"),
      contract: "PHE",
      dragItems: JSON.stringify(cacheOptionData)
    };

    axios({
      method: "POST",
      url: `${config.server_url}/saveLayout`,
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
      .then((response) => {
        if (!response.data.error) {
          PSNotification({
            type: "success",
            message: "Success",
            description: "Layout saved successfully!",
          });
        } else {
          PSNotification({
            type: "error",
            message: "Error",
            description: "Layout not saved!",
          });
        }
      })
      .catch((err) => {
        PSNotification({
          type: "error",
          message: "Error",
          description: "Layout not saved!",
        });
      });
  };

  const onCalculateSettingHandler = (event) => {
    setVisibleCompareOption(true);
  };

  const onChangeCompareOption = () => {
    setCompareHistory(prev => {
      const current = prev;
      const item = prev.find(obj => {
        return obj.start == firstMonth.format("YYYY-MM-01") && obj.end == lastMonth.format("YYYY-MM-01");
      })

      if (typeof item !== "undefined") {
        return current;
      }

      current.push({
        start: firstMonth.format("YYYY-MM-01"),
        end: lastMonth.format("YYYY-MM-01")
      })
      return current;
    })

    setVisibleCompareOption(false);
  };

  const onCancelCompare = () => {
    setVisibleCompareOption(false);
  };

  const onChangeAlignHandler = (event) => {
    setChangeAlign((prev) => !prev);
  };

  const onFirstMonthChange = (value) => {
    setFirstMonth(value);
  };

  const onLastMonthChange = (value) => {
    setLastMonth(value);
  };

  const onStripFeatureHandler = (event) => {
    setVisibleStripOption(true);
  };

  const onStripFirstMonthChange = (value) => {
    setStripFirstMonth(value);
  };

  const onStripLastMonthChange = (value) => {
    setStripLastMonth(value);
  };

  const onCancelStrip = () => {
    setVisibleStripOption(false);
  };

  const onChangeStripOption = () => {
    setStripHistory(prev => {
      const current = prev;
      console.log('prev', prev);
      const item = prev.find(obj => {
        return obj.start == stripFirstMonth.format("YYYY-MM-01") && obj.end == stripLastMonth.format("YYYY-MM-01");
      })

      if (typeof item !== "undefined") {
        return current;
      }

      current.push({
        start: stripFirstMonth.format("YYYY-MM-01"),
        end: stripLastMonth.format("YYYY-MM-01")
      });
      console.log('current', current);
      return current;
    })
    setVisibleStripOption(false);
  };

  const onStraddleHandler = () => {
    setStraddleVisible(prev => !prev);
  }
  // socket.on("updated_notify", (response) => {
  //   setCacheOptionData((prev) => response.data); // Save response to cacheOptionData
  // });

  const onDisableFirstMonth = (date) => {
    return (
      date &&
      (date < minMonth || date > maxMonth)
    );
  };

  const onDisableLastMonth = (date) => {
    return (
      date &&
      (date < minMonth || date > maxMonth)
    );
  };

  const onStripDisableFirstMonth = (date) => {
    return (
      date &&
      (date < startMonth || date >= stripLastMonth)
    );
  }; 

  const onStripDisableLastMonth = (date) => {
    return (
      date &&
      (date <= stripFirstMonth || date > maxMonth)
    );
  };

  return (
    <>
      <Spin size="large" spinning={spinning} tip="Loading...">
        <Row justify="center">
          <Col span={24}>
            <Card>
              <Row gutter={[10, 10]} justify="center">
                <Col xs={24} sm={16} md={5}>
                  <Search
                    addonBefore={selectBefore}
                    placeholder="input search text"
                    onSearch={onSearch}
                    enterButton
                    size="small"
                  />
                </Col>
                {!isMobile && (<><Col span={24} sm={12} md={3}>
                  <Select
                    placeholder="Rows"
                    onChange={onShowRowChange}
                    size="small"
                    style={{ width: "100%" }}
                    value={showStrikeCount}
                  >
                    <Option disabled>Strike Count</Option>
                    <Option value={5}>5</Option>
                    <Option value={10}>10</Option>
                    <Option value={25}>25</Option>
                    <Option value={50}>50</Option>
                    <Option value={100}>100</Option>
                  </Select>
                </Col>
                <Col xs={24} sm={12} md={5}>
                  <Input.Group compact size="small">
                    <Input
                      type="number"
                      min={0}
                      max={100}
                      value={startStrike}
                      style={{ width: "45%", textAlign: "center" }}
                      placeholder="Minimum"
                      onChange={onChangeStartStrikeHandler}
                    />
                    <Input
                      className="site-input-split"
                      style={{
                        width: "10%",
                        borderLeft: 0,
                        borderRight: 0,
                        pointerEvents: "none",
                      }}
                      placeholder="~"
                      disabled
                    />
                    <Input
                      type="number"
                      min={0}
                      max={100}
                      value={endStrike}
                      className="site-input-right"
                      style={{
                        width: "45%",
                        textAlign: "center",
                      }}
                      placeholder="Maximum"
                      onChange={onChangeEndStrikeHandler}
                    />
                  </Input.Group>
                </Col>
                <Col span={24} sm={12} md={5}>
                  <RangePicker
                    value={[startMonth, endMonth]}
                    picker="month"
                    size="small"
                    style={{ width: "100%" }}
                    onChange={onDateRangeChange}
                    disabledDate={disabledDate}
                  />
                </Col>
                <Col xs={24} sm={12} md={4} style={{ whiteSpace: "nowrap", textAlign:"center"}}>
                  <Tooltip placement="top" title={"Refresh Data"}>
                    <Button
                      type="primary"
                      icon={<DatabaseOutlined style={{ fontSize: 15 }} />}
                      size="small"
                      onClick={refreshData}
                    ></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={"Save Layout"}>
                    <Button
                      type="primary"
                      icon={<SaveOutlined style={{ fontSize: 15 }} />}
                      size="small"
                      onClick={onSaveLayoutHandler}
                      style={{ marginLeft: 10 }}
                    ></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={"Compare"}>
                    <Button
                      type="primary"
                      icon={<MergeCellsOutlined style={{ fontSize: 15 }} />}
                      size="small"
                      onClick={onCalculateSettingHandler}
                      style={{ marginLeft: 10 }}
                    ></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={"Align"}>
                    <Button
                      type="primary"
                      icon={<MenuUnfoldOutlined style={{ fontSize: 15 }} />}
                      size="small"
                      onClick={onChangeAlignHandler}
                      style={{ marginLeft: 10 }}
                    ></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={"Strip"}>
                    <Button
                      type="primary"
                      icon={<FundViewOutlined style={{ fontSize: 15 }} />}
                      size="small"
                      onClick={onStripFeatureHandler}
                      style={{ marginLeft: 10 }}
                    ></Button>
                  </Tooltip>
                  <Tooltip placement="top" title={"Straddle"}>
                    <Button
                      type="primary"
                      icon={<SlidersOutlined style={{ fontSize: 15 }} />}
                      size="small"
                      onClick={onStraddleHandler}
                      style={{ marginLeft: 10 }}
                    ></Button>
                  </Tooltip>
                </Col></>)}
              </Row>
              <Divider className="main-divider" />
              {!isMobile && (<>
                {(!changeAlign && !isTablet) ? (
                  <Row gutter={[16, 16]} className="option-table-content">
                    <GridContextProvider onChange={onDragChange}>
                      <GridDropZone
                        className="dropzone noselect"
                        id="left"
                        boxesPerRow={straddleVisible ? dragItems.length : dragItems.length - 1}
                        rowHeight={500}
                        //style={{ maxWidth: showMonthCount * 300 }}
                        disableDrag={disableDraggable}
                      >
                        {straddleVisible ? (
                          dragItems.map((item, index) => (
                            <GridItem key={item.id} className="GridItem">
                              <PSDragColumntable
                                columns={item.columns}
                                dataSource={item.dataSource}
                                id={item.id}
                                onDrag={(e) => {
                                  setDisableDraggable(e);
                                }}
                                monthTitle={item.monthTitle}
                                alignStatus={false}
                                straddle={index == 0 ? true : false}
                              />
                            </GridItem>
                          ))
                        ) : (
                          dragItems.slice(1).map((item) => (
                            <GridItem key={item.id} className="GridItem">
                              <PSDragColumntable
                                columns={item.columns}
                                dataSource={item.dataSource}
                                id={item.id}
                                onDrag={(e) => {
                                  setDisableDraggable(e);
                                }}
                                monthTitle={item.monthTitle}
                                alignStatus={false}
                                straddle={false}
                              />
                            </GridItem>
                          ))
                        )}
                      </GridDropZone>
                    </GridContextProvider>
                  </Row>
                ) : (
                  <Row gutter={[16, 16]} className="option-table-algin-container" wrap={false}>
                    {straddleVisible ? (
                      dragItems.map((item, index) => (
                        <PSDragColumntable
                          columns={item.columns}
                          dataSource={item.dataSource}
                          id={item.id}
                          onDrag={(e) => {
                            setDisableDraggable(e);
                          }}
                          monthTitle={item.monthTitle}
                          alignStatus={index == 0 ? false : true}
                        />
                      ))
                    ) : (
                      dragItems.slice(1).map((item, index) => (
                        <PSDragColumntable
                          columns={item.columns}
                          dataSource={item.dataSource}
                          id={item.id}
                          onDrag={(e) => {
                            setDisableDraggable(e);
                          }}
                          monthTitle={item.monthTitle}
                          alignStatus={index == 0 ? false : true}
                        />
                      ))
                    )}
                  </Row>
                )}
              </>)}
            </Card>
          </Col>
        </Row>
      </Spin>
      <Modal
        title="Compare Option"
        style={{ top: 20 }}
        visible={visibleCompareOption}
        onOk={onChangeCompareOption}
        onCancel={onCancelCompare}
        key="copmare-option"
      >
        <Form
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 18 }}
          layout="horizontal"
          size={"medium"}
        >
          <Form.Item label="First Month">
            <DatePicker
              picker={"month"}
              style={{ width: "100%" }}
              value={firstMonth}
              onChange={onFirstMonthChange}
              disabledDate={onDisableFirstMonth}
            />
          </Form.Item>
          <Form.Item label="Last Month">
            <DatePicker
              picker={"month"}
              style={{ width: "100%" }}
              value={lastMonth}
              onChange={onLastMonthChange}
              disabledDate={onDisableLastMonth}
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Strip Feature"
        style={{ top: 20 }}
        visible={visibleStripOption}
        onOk={onChangeStripOption}
        onCancel={onCancelStrip}
        key="strip-option"
      >
        <Spin size="large" spinning={stripSpinning} tip="Loading...">
          <Form
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            size={"medium"}
          >
            <Form.Item label="First Month">
              <DatePicker
                picker={"month"}
                style={{ width: "100%" }}
                value={stripFirstMonth}
                onChange={onStripFirstMonthChange}
                disabledDate={onStripDisableFirstMonth}
              />
            </Form.Item>
            <Form.Item label="Last Month">
              <DatePicker
                picker={"month"}
                style={{ width: "100%" }}
                value={stripLastMonth}
                onChange={onStripLastMonthChange}
                disabledDate={onStripDisableLastMonth}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
};
export default PSHome;
