import React from "react";
import { notification, Table } from "antd";

export const PSSearchResult = (props) => {
  notification.open({
    message: props.message,
    description: (
      <Table 
        columns={props.columns}
        dataSource={props.dataSource}
        pagination={false}
        size={"small"}
        scroll={{ x: 180 }}
      />
    ),
    key: props.key,
    duration: 0,
    calssName: "search-result-panel",
    placement: "bottomRight",
    onClose: () => props.searchClose(props.key)
  });
};

export default PSSearchResult;