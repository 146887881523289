import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Select, Row, Col } from "antd";
import axios from "axios";
import { useMediaQuery } from "react-responsive";

import PSSpotPrice from "../PSSpotPrice";
import PSFutureItem from "../PSFutureItem";

import "./psNavBar.css";
import {
  BankTwoTone,
  SettingTwoTone,
  UnlockTwoTone,
  HomeOutlined,
} from "@ant-design/icons";

const qs = require("qs");
const config = require("../../config.js");

const { Option } = Select;

export const PSNavBar = () => {
  /* Responsive */
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1024 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1024, minWidth: 769 });
  const isMobile = useMediaQuery({ maxWidth: 480 });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [privi, setPrivi] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    let unmount = false;
    const user = localStorage.getItem("currentUser");
    if (typeof user !== "undefined" && user != null) {
      const userInfo = JSON.parse(user);
      setIsLoggedIn(true);
      setCurrentUser(userInfo);

      if (typeof userInfo.privi !== "undefined" && userInfo.privi != "") {
        setPrivi((prev) => userInfo.privi.split(","));
      }

      const requestData = {
        userid: JSON.parse(user).ID,
      };

      axios({
        url: `${config.server_url}/getFeatureOptions`,
        method: "POST",
        data: qs.stringify(requestData),
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        },
      })
        .then((response) => {
          if (!response.data.error) {
            setSelectedProducts((prev) => response.data.res);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
    return () => {
      unmount = true;
    };
  }, []);

  const logout = (event) => {
    event.preventDefault(); // prevent page transition
    const user = localStorage.getItem("currentUser");
    const userInfo = JSON.parse(user);
    axios({
      url: `${config.server_url}/logout`,
      method: "POST",
      data: qs.stringify({
        user_id: userInfo.ID
      }),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
    .then((response) => {
      localStorage.clear();
      window.location.reload(); // stay at the same url
    })
    .catch((error) => {
      console.log(error);
    });
    
  };

  const showFeatures = (event) => {
    setVisible(true);
  };

  const handleOptionChange = (value) => {
    setSelectedProducts((prev) => value);
  };

  const onCloseItem = (value) => {
    setSelectedProducts((prev) => {
      const current = prev;
      return current.filter((ele) => {
        return ele != value;
      });
    });
  };

  if (isLoggedIn) {
    return (
      <header>
        <nav className="navbar navbar-default navbar-static-top navbar-main">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img src="assets/images/home-icon.png" alt="" title="" />
            </a>
          </div>
          <button
            type="button"
            className="navbar-toggle"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <ul className="nav navbar-top-links navbar-right">
            {currentUser.ID > 0 ?(
            <li>
              <a className="dropdown-toggle" data-toggle="dropdown" href="#">
                <HomeOutlined /> {currentUser.display_name}{" "}
                <i className="fa fa-caret-down"></i>
              </a>
              {currentUser.is_admin == 1 ? (
                <ul className="dropdown-menu dropdown-user">
                  <li>
                    <a href="/admin">
                      <SettingTwoTone /> User Management
                    </a>
                  </li>
                  <li>
                    <a href="/logout" onClick={logout}>
                      <UnlockTwoTone /> Logout
                    </a>
                  </li>
                </ul>
              ) : (
                <ul className="dropdown-menu dropdown-user">
                  <li>
                    <a href="/logout" onClick={logout}>
                      <UnlockTwoTone /> Logout
                    </a>
                  </li>
                </ul>
              )}
            </li>
            ):
            (
              <li>
                <NavLink to="/login">
                  Log In
                </NavLink>
              </li>
            )
            }
          </ul>
          <ul
            className="nav navbar-top-links navbar-left navbar-collapse collapse navbar-menu"
            id="bs-example-navbar-collapse-1"
          >
            {privi.indexOf("PHE") !== -1 && (
              <li>
                <NavLink exact to="/">
                  HENRY (NG)
                </NavLink>
              </li>
            )}
            {privi.indexOf("T") !== -1 && (
              <li>
                <NavLink to="/cl">WTI</NavLink>
              </li>
            )}
            {privi.indexOf("R") !== -1 && (
              <li>
                <NavLink to="/wti_cma">WTI CMA</NavLink>
              </li>
            )}
            {privi.indexOf("B") !== -1 && (
              <li>
                <NavLink to="/brent">BRENT</NavLink>
              </li>
            )}
            {privi.indexOf("I") !== -1 && (
              <li>
                <NavLink to="/brent_cma">BRENT CMA</NavLink>
              </li>
            )}
            {privi.indexOf("Pricer") !== -1 && (
              <li>
                <NavLink to="/pricer">PRICER</NavLink>
              </li>
            )}
          </ul>
          {(isDesktopOrLaptop || isTabletOrMobile) && (
            <ul className="nav navbar-top-links navbar-right spot-price-nav-bar">
              <PSSpotPrice />
            </ul>
          )}
        </nav>

        {isDesktopOrLaptop && (
          <>
            <div className="settlement-future-items">
              <Row justify="start" align="bottom" gutter={[20, 0]}>
                {selectedProducts.map((product, index) => (
                  <Col span="5" key={index}>
                    <PSFutureItem product={product} close={onCloseItem} />
                  </Col>
                //   <Col flex={1} key={index}>
                //   <PSFutureItem product={product} close={onCloseItem} />
                // </Col>
                ))}
              </Row>
            </div>
            <div className="future-content">
              <div className="settlement-feature-option">
                <Row justify="center" gutter={[10, 10]}>
                  <Col span="16">
                    <div className="option">
                      <Select
                        mode="multiple"
                        allowClear
                        style={{ width: "100%" }}
                        placeholder="Please select"
                        value={selectedProducts}
                        onChange={handleOptionChange}
                        size={"small"}
                      >
                        <Option value="PHE">Henry (NG)</Option>
                        <Option value="T">WTI (CL)</Option>
                        <Option value="R">WTI CMA</Option>
                        <Option value="B">BRENT</Option>
                        <Option value="I">BRENT CMA</Option>
                      </Select>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </>
        )}
      </header>
    );
  } else {
    return (
      <header>
        <nav className="navbar navbar-default navbar-static-top">
          <div className="navbar-header">
            <a className="navbar-brand" href="/">
              <img src="assets/images/home-icon.png" alt="" title="" />
            </a>
          </div>
          <ul className="nav navbar-top-links navbar-right login-nav">
            <li>
              <a href="/login">Login</a>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
};

export default PSNavBar;
