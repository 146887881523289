import React, { useEffect, useState } from "react";
import { Table } from "antd";
import ReactDragListview from "react-drag-listview";
import axios from "axios";

import "./pssettletable.css";

const qs = require("qs");
const config = require("../../config.js");

export const PSSettleTable = (props) => {
  const featureTableTitle = config.products[props.product];

  const [columns, setColumns] = useState([
    {
      title: "Month",
      dataIndex: "month",
      key: "month",
    },
    {
      title: "Settle",
      dataIndex: "settle",
      key: "settle",
    },
    {
      title: "Current",
      dataIndex: "current",
      key: "current",
    },
    {
      title: "Change",
      dataIndex: "change",
      key: "change",
    },
  ]);

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    getTableData();
    const timer = setInterval(() => getTableData(), 9000);
    return () => clearInterval(timer);
  }, []);

  const getTableData = () => {
    const requestData = {
      product: props.product,
    };

    axios({
      url: `${config.server_url}/getSettleData`,
      method: "POST",
      data: qs.stringify(requestData),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      },
    })
      .then((response) => {
        if (!response.data.error) {
          setDataSource((prev) => {
            const newDataSource = [];
            for (let i = 0; i < response.data.res.length; i++) {
              newDataSource.push({
                key: i,
                month: response.data.res[i].strip,
                settle: response.data.res[i].settlement,
                current: response.data.res[i].settlement,
                change: response.data.res[i].net_change,
              });
            }
            return newDataSource;
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const current = [...columns];
      const item = current.splice(fromIndex, 1)[0];
      current.splice(toIndex, 0, item);
      setColumns((prev) => current);
    },
    nodeSelector: "th",
  };

  return (
    <div className="settlement-feature-item">
      <div className="settlement-feature-table">
        <ReactDragListview.DragColumn {...dragProps}>
          <Table
            dataSource={dataSource}
            columns={columns}
            bordered
            size="small"
            pagination={false}
            rowClassName={"table-row"}
          />
        </ReactDragListview.DragColumn>
      </div>
    </div>
  );
};

export default PSSettleTable;
