import {React, useState} from "react";
import axios from 'axios';
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import './psRegister.css';

const qs = require('qs');
const config = require("../../config.js");

export const PSRegister = () => {
  const [newUser, setNewUser] = useState({
    tnc: false,
    teamName: "",
    userName: "",
    userEmail: "",
    password: "",
    confirmPassword: ""
  });

  const [alertStatus, setAlertStatus] = useState({
    open: false,
    varient: "success",
    message: "New message"
  });

  const handleChange = (event) => {
    var key = event.target.name;
    setNewUser({
      ...newUser,
      [key]: event.target.value
    })
  }

  const handleTNC = (event) => {
    setNewUser({
      ...newUser,
      tnc: event.target.checked
    })
  }

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertStatus({
      ...alertStatus,
      open: false
    });
  }

  const handleSubmit = (event) => {
    if (newUser.tnc === false) {
      setAlertStatus({
        varient: "error",
        message: "Please check Terms and Conditions!",
        open: true
      });
    } else if (newUser.teamName === ""
              || newUser.username === ""
              || newUser.userEmail === ""
              || newUser.password === "") {

      setAlertStatus({
        varient: "error",
        message: "Please Fill out the fields!",
        open: true
      });
    } else if (newUser.password != newUser.confirmPassword) {
      setAlertStatus({
        varient: "error",
        message: "Password and Confirm Password does not matched!",
        open: true
      });
    } else {
      const data = {
        team_name: newUser.teamName,
        user_name: newUser.userName,
        user_email: newUser.userEmail,
        password: newUser.password,
        confirm_password: newUser.confirmPassword,
        tnc: newUser.tnc
      };

      axios({
        method: "POST",
        url: `${config.server_url}/register`,
        data: qs.stringify(data),
        config: {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        }
      }).then(response => {
        if (response.data.error) {
          setAlertStatus({
            varient: "error",
            message: response.data.res,
            open: true
          });  
        } else {
          setNewUser({
            tnc: false,
            teamName: "",
            userName: "",
            userEmail: "",
            password: "",
            confirmPassword: ""
          });

          setAlertStatus({
            varient: "success",
            message: response.data.res,
            open: true
          });

          setTimeout(() => {
            window.location.href = "/login";
          }, 1000);
        }
      }).catch(error => {
        setAlertStatus({
          varient: "error",
          message: "Something has wrong. Please try again later.",
          open: true
        });
      })
      
      setAlertStatus({
        varient: "success",
        message: "Registered successfully!",
        open: true
      });
    }
  }

  return (
    <>
      <Grid container spacing={3} justify="center" alignItems="flex-start">
        <Grid item xs={10} sm={6} md={5}>
          <Paper className="login-panel">
            <div className="card">
              <div className="card-header text-left">
                <h3 className="card-header-title">Register </h3>
              </div>
              <Divider />
              <div className="card-body">
                <div className="form-input-group">
                  <TextField required name="teamName" label="Team Name" className="form-input" value={newUser.teamName} onChange={handleChange} />
                </div>
                <div className="form-input-group">
                  <TextField required name="userName" label="Name" className="form-input" value={newUser.userName} onChange={handleChange} />
                </div>
                <div className="form-input-group">
                  <TextField required name="userEmail" label="E-mail Address" className="form-input" value={newUser.userEmail} onChange={handleChange}/>
                </div>
                <div className="form-input-group">
                  <TextField required name="password" label="Password" className="form-input" type="password" value={newUser.password} onChange={handleChange}/>
                </div>
                <div className="form-input-group">
                  <TextField required name="confirmPassword" label="Confirm Password" className="form-input" type="password" value={newUser.confirmPassword} onChange={handleChange}/>
                </div>
                <div className="form-input-group">
                  <FormControlLabel
                    className="form-checkbox-input"
                    control={
                      <Checkbox
                        checked={newUser.tnc}
                        onChange={handleTNC}
                        name="tnc"
                        color="primary"
                      />
                    }
                    label={"I Accept Terms of Service"}
                  />
                </div>
              </div>
              <Divider />
              <div className="card-footer">
                <Grid container justify="center">
                  <Grid item xs={12} >
                    <div className="text-center">
                      <Button variant="contained" className="primary-btn" startIcon={<CheckCircleOutlineOutlinedIcon />} onClick={handleSubmit}>
                        Register
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar open={alertStatus.open} autoHideDuration={6000} onClose={handleAlertClose} className="alert-font">
        <Alert onClose={handleAlertClose} severity={alertStatus.varient}>
          {alertStatus.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default PSRegister;