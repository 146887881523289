import {React, useState} from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import cookie from 'react-cookies';
import axios from 'axios';
import './psForgotPassword.css';

const qs = require('qs');
const config = require("../../config.js");

export const PSForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [alertStatus, setAlertStatus] = useState({
    open: false,
    varient: "success",
    message: "New message"
  });

  const handleEmail = (event) => {
    setEmail(event.target.value)
  }
  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertStatus({
      ...alertStatus,
      open: false
    });
  }

  const handleSubmit = (event) => {
    const data = {
      email: email,
    };

    axios({
      method: "POST",
      url: `${config.server_url}/forgot-password`,
      data: qs.stringify(data),
      config: {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      }
    }).then(response => {
      console.log(response);
      if (response.data.error) {
        setAlertStatus({
          varient: "error",
          message: response.data.res,
          open: true
        });
      } else {
        // const userString = JSON.stringify(response.data.res)

        // localStorage.setItem('currentUser', userString)

        setAlertStatus({
          varient: "success",
          message: "Reset password link sent successfully.",
          open: true
        });

        // setTimeout(() => {
        //   window.location.href = "/";
        // }, 1000);
      }
      
    }).catch(error => {
      setAlertStatus({
        varient: "error",
        message: "Something has wrong. Please try again later.",
        open: true
      });
    })
  }

  return (
    <>
      <Grid container justify="center" alignItems="flex-start">
        <Grid item xs={10} sm={6} md={5}>
          <Paper className="login-panel">
            <div className="card">
              <div className="card-header text-left">
                <h3 className="card-header-title">Forgot Password? </h3>
              </div>
              <Divider />
              <div className="card-body">
                <div className="form-input-group">
                  <TextField required id="email" label="E-mail Address" className="form-input" value={email} onChange={handleEmail} />
                </div>
                
              </div>
              <Divider />
              <div className="card-footer">
                <Grid container justify="center">
                  <Grid item xs={6} >
                    <div className="text-right">
                      <Button variant="contained" className="primary-btn" onClick={handleSubmit}>
                        Send Link
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar open={alertStatus.open} autoHideDuration={6000} onClose={handleAlertClose} className="alert-font">
        <Alert onClose={handleAlertClose} severity={alertStatus.varient}>
          {alertStatus.message}
        </Alert>
      </Snackbar>
    </>
  );
}

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default PSForgotPassword;